import React from 'react';
import { Layout } from 'components/layout/layout';
import { useLocation } from '@reach/router';
import 'styles/default.scss';
import { Link } from 'gatsby';
import { allPrefactures } from 'const/prefacture';
import { prefactureBounds } from 'const/bounds';
import { Seo } from 'components/seo';

// 開発時しか表示しない
const allowDomains = [
  'pansuku-app-dev-bakery-gift.web.app',
  'panforyou-media-dev-ticket.web.app',
  'bakery-gift-map-update.web.app',
  'localhost:8000',
  'localhost:9000',
];
const isBrowser = typeof window !== 'undefined';

const PreviewShopsPage = () => {
  const { host } = useLocation();
  if (!allowDomains.includes(host) && isBrowser) {
    window.location.href = '/404.html';

    return null;
  }
  const rows = allPrefactures.map((p, index) => (
    <tr
      key={p}
      style={{ backgroundColor: index % 2 === 0 ? '#cccc' : '#eeee' }}
    >
      <td>{index}</td>
      <td>
        <Link to={`/map?prefacture=${p}&boundsLine=true`} target="_blank">
          {p}
        </Link>
      </td>
      <td>
        <pre>{JSON.stringify(prefactureBounds[p].bounds)}</pre>
      </td>
    </tr>
  ));

  return (
    <Layout>
      <Seo title="prefactureList" />
      <main>
        <h1>prefactureList</h1>
        <table>
          <thead>
            <tr>
              <th style={{ width: '50px' }}>index</th>
              <th style={{ fontSize: '14px' }}>都道府県</th>
              <th style={{ fontSize: '14px' }}>GPS座標</th>
            </tr>
          </thead>
          <tbody>{rows}</tbody>
        </table>
      </main>
    </Layout>
  );
};

export default PreviewShopsPage;
