export const prefactureBounds = {
  北海道: {
    bounds: {
      northeast: { lat: 43.78133121761507, lng: 140.93136645583994 },
      southwest: { lat: 42.170164411985326, lng: 141.99834476648923 },
    },
  },
  青森県: {
    bounds: {
      northeast: { lat: 41.07749655591925, lng: 140.4993968671699 },
      southwest: { lat: 40.26660863042318, lng: 141.01571536919147 },
    },
  },
  岩手県: {
    bounds: {
      northeast: { lat: 39.93164273286869, lng: 141.0500206258293 },
      southwest: { lat: 39.508998974218855, lng: 141.2991533113844 },
    },
  },
  宮城県: {
    bounds: {
      northeast: { lat: 38.45660844236111, lng: 140.75268224729862 },
      southwest: { lat: 38.02778467141063, lng: 141.00341912188875 },
    },
  },
  秋田県: {
    bounds: {
      northeast: { lat: 39.955856567303364, lng: 139.982773565226 },
      southwest: { lat: 39.57548278250823, lng: 140.22617303527386 },
    },
  },
  山形県: {
    bounds: {
      northeast: { lat: 38.74838777305067, lng: 140.05969902758417 },
      southwest: { lat: 37.87214760822749, lng: 140.53254486896705 },
    },
  },
  福島県: {
    bounds: {
      northeast: { lat: 37.939265062231726, lng: 140.3454128872144 },
      southwest: { lat: 37.5433305288295, lng: 140.5894009379043 },
    },
  },
  茨城県: {
    bounds: {
      northeast: { lat: 36.80297099387776, lng: 140.10101950278832 },
      southwest: { lat: 35.90793027181953, lng: 140.61201657693442 },
    },
  },
  栃木県: {
    bounds: {
      northeast: { lat: 36.803337885830906, lng: 139.75918691741012 },
      southwest: { lat: 36.38334809211862, lng: 140.01658812842464 },
    },
  },
  群馬県: {
    bounds: {
      northeast: { lat: 36.62230226807922, lng: 138.92997604903525 },
      southwest: { lat: 36.21888517548426, lng: 139.16613823717762 },
    },
  },
  埼玉県: {
    bounds: {
      northeast: { lat: 36.139199429332116, lng: 139.4556646005875 },
      southwest: { lat: 35.69185724224085, lng: 139.70849474624518 },
    },
  },
  千葉県: {
    bounds: {
      northeast: { lat: 35.86809623806196, lng: 139.97422442011103 },
      southwest: { lat: 35.42462721335208, lng: 140.2299199225964 },
    },
  },
  東京都: {
    bounds: {
      northeast: { lat: 35.76738877206122, lng: 139.67733264902566 },
      southwest: { lat: 35.59448740597112, lng: 139.80230213784074 },
    },
  },
  神奈川県: {
    bounds: {
      northeast: { lat: 35.669673967141414, lng: 139.47752013255024 },
      southwest: { lat: 35.21825690246099, lng: 139.7333693309761 },
    },
  },
  新潟県: {
    bounds: {
      northeast: { lat: 38.118358758208224, lng: 138.78446433357186 },
      southwest: { lat: 37.34998936683546, lng: 139.31537416787637 },
    },
  },
  富山県: {
    bounds: {
      northeast: { lat: 36.88484899520119, lng: 137.0704450372504 },
      southwest: { lat: 36.44081485084019, lng: 137.33108761268133 },
    },
  },
  石川県: {
    bounds: {
      northeast: { lat: 36.70332184667837, lng: 136.60103430130692 },
      southwest: { lat: 36.478000045469365, lng: 136.7266737536075 },
    },
  },
  福井県: {
    bounds: {
      northeast: { lat: 36.289872947196365, lng: 136.08170215174806 },
      southwest: { lat: 35.86331603011129, lng: 136.34312806559572 },
    },
  },
  山梨県: {
    bounds: {
      northeast: { lat: 35.73950457303554, lng: 138.51119836321277 },
      southwest: { lat: 35.519907157798734, lng: 138.63261020673397 },
    },
  },
  長野県: {
    bounds: {
      northeast: { lat: 36.81180273105696, lng: 138.1016203679175 },
      southwest: { lat: 36.37960001796792, lng: 138.33370654686726 },
    },
  },
  岐阜県: {
    bounds: {
      northeast: { lat: 35.62007745224421, lng: 136.63916494066197 },
      southwest: { lat: 35.17878632148913, lng: 136.8978266800843 },
    },
  },
  静岡県: {
    bounds: {
      northeast: { lat: 35.489016952884754, lng: 138.01395603886596 },
      southwest: { lat: 34.5702325186193, lng: 138.5089427809685 },
    },
  },
  愛知県: {
    bounds: {
      northeast: { lat: 35.37079749031272, lng: 136.78118549630005 },
      southwest: { lat: 34.93384940608171, lng: 137.04050412353706 },
    },
  },
  三重県: {
    bounds: {
      northeast: { lat: 35.065946550404156, lng: 136.4404950681801 },
      southwest: { lat: 34.606513134699604, lng: 136.69493840996324 },
    },
  },
  滋賀県: {
    bounds: {
      northeast: { lat: 35.27378385151318, lng: 135.836069510816 },
      southwest: { lat: 34.82126332268781, lng: 136.0915016354654 },
    },
  },
  京都府: {
    bounds: {
      northeast: { lat: 35.05448126035753, lng: 135.73194817103476 },
      southwest: { lat: 34.93953763261545, lng: 135.79789306459736 },
    },
  },
  大阪府: {
    bounds: {
      northeast: { lat: 34.78303592477936, lng: 135.43526106299083 },
      southwest: { lat: 34.555619174369404, lng: 135.56267678814845 },
    },
  },
  兵庫県: {
    bounds: {
      northeast: { lat: 34.936350869296874, lng: 135.01323623255686 },
      southwest: { lat: 34.481397679875926, lng: 135.27759474963506 },
    },
  },
  奈良県: {
    bounds: {
      northeast: { lat: 34.75578221911545, lng: 135.71747792316464 },
      southwest: { lat: 34.52911494944854, lng: 135.84622784477642 },
    },
  },
  和歌山県: {
    bounds: {
      northeast: { lat: 34.39635851490697, lng: 135.0649425728399 },
      southwest: { lat: 33.92280481321292, lng: 135.31586777719875 },
    },
  },
  鳥取県: {
    bounds: {
      northeast: { lat: 35.599137420365054, lng: 134.16578085154035 },
      southwest: { lat: 35.37412258483897, lng: 134.29210688830003 },
    },
  },
  島根県: {
    bounds: {
      northeast: { lat: 35.50997063761555, lng: 133.03231946759416 },
      southwest: { lat: 35.39738787231586, lng: 133.09891246772 },
    },
  },
  岡山県: {
    bounds: {
      northeast: { lat: 34.75779297219213, lng: 133.85593306892892 },
      southwest: { lat: 34.53095591172564, lng: 133.98580783420067 },
    },
  },
  広島県: {
    bounds: {
      northeast: { lat: 34.50381810718019, lng: 132.3916204763261 },
      southwest: { lat: 34.272248001606656, lng: 132.52055745259244 },
    },
  },
  山口県: {
    bounds: {
      northeast: { lat: 34.218116279301924, lng: 131.42997290756472 },
      southwest: { lat: 34.095874712651636, lng: 131.49399307203768 },
    },
  },
  徳島県: {
    bounds: {
      northeast: { lat: 34.46920664435687, lng: 134.2883384227255 },
      southwest: { lat: 33.53796449504147, lng: 134.77036359357177 },
    },
  },
  香川県: {
    bounds: {
      northeast: { lat: 34.50698469714799, lng: 133.91342997061602 },
      southwest: { lat: 34.04853851029813, lng: 134.1523826197294 },
    },
  },
  愛媛県: {
    bounds: {
      northeast: { lat: 34.04170252159243, lng: 132.66088041378592 },
      southwest: { lat: 33.59142439555918, lng: 132.89300165313253 },
    },
  },
  高知県: {
    bounds: {
      northeast: { lat: 33.65945679687964, lng: 133.46622564138144 },
      southwest: { lat: 33.43264162417245, lng: 133.53466834598774 },
    },
  },
  福岡県: {
    bounds: {
      northeast: { lat: 33.82345333651111, lng: 130.25218222079445 },
      southwest: { lat: 33.38854602268223, lng: 130.50894320209392 },
    },
  },
  佐賀県: {
    bounds: {
      northeast: { lat: 33.5430068508642, lng: 130.15906339774776 },
      southwest: { lat: 33.07469545099537, lng: 130.41368775873525 },
    },
  },
  長崎県: {
    bounds: {
      northeast: { lat: 33.255818588467164, lng: 129.62857605070198 },
      southwest: { lat: 32.39725890796047, lng: 130.10030153804064 },
    },
  },
  熊本県: {
    bounds: {
      northeast: { lat: 33.148953280349055, lng: 130.40929201684114 },
      southwest: { lat: 32.22432183610278, lng: 130.91054326304584 },
    },
  },
  大分県: {
    bounds: {
      northeast: { lat: 33.701603151090616, lng: 131.40835339678907 },
      southwest: { lat: 32.815089895375976, lng: 131.93569717414277 },
    },
  },
  宮崎県: {
    bounds: {
      northeast: { lat: 32.21004221513304, lng: 131.3092487385842 },
      southwest: { lat: 31.70131503673352, lng: 131.5968978267616 },
    },
  },
  鹿児島県: {
    bounds: {
      northeast: { lat: 31.786171006272852, lng: 130.4267994618609 },
      southwest: { lat: 31.33448864817783, lng: 130.66300552880057 },
    },
  },
  沖縄県: {
    bounds: {
      northeast: { lat: 26.478162351877476, lng: 127.64451127118538 },
      southwest: { lat: 26.007638679395086, lng: 127.88346392029878 },
    },
  },
};
